import React from 'react'

function Footer() {
  return (
    <section className="footer-area">
    <div className="container">
        <div className="row">
            <div className="col-md-3">
                <p><a href="/privacy-policy" target="_blank">Website Privacy Policy</a>
                </p>

            </div>
            <div className="col-md-3">
                <p><a href="/terms-and-conditions" target="_blank">Terms Of Usage</a>
                </p>

            </div>
            <div className="col-md-3">
                <p>For questions, reach out to <b>info@movhealth.com</b></p>
            </div>
            <div className="col-md-3">
                <p><img src={require("../assets/images/copyright.png")} alt='copyright' />2022 MOV Health. All rights reserved.</p>
            </div>
        </div>
    </div>
</section>
  )
}

export default Footer
