import React from 'react'
import OneKit from '../components/OneKit'
import Banner from '../components/Banner'
import PointSection from '../components/PointSection'
import Simplify from '../components/Simplify'
import ComingSoon from '../components/ComingSoon'
import Footer from '../components/Footer'

function LandingPage() {
    return (
        <div>
            <Banner />
            <OneKit />
            <PointSection />
            <Simplify />
            <ComingSoon />
            <Footer />
        </div>
    )
}

export default LandingPage
