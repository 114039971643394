import React,{ useEffect, useState } from "react";

import parse from 'html-react-parser';
import axios from "axios";
// import './style.css';

export const TermsAndConditionData = () => {
  const [addData, setAddData] = useState('')

  useEffect(() => {
    document.title = 'MOV || TERMS OF SERVICE'
  },[])

  const getTermsOfUses = async()=>{
    const response = await axios.get('https://api-backend.movhealth.com/api/v1/cms/terms_of_use',{
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-api-key": "mv52ea2d-4567-4956-9d19-35a7e75a2c17",
            "platform": "web"
        }
    })
    console.log(response)
    setAddData(response.data)
  }
  useEffect(() => {
    getTermsOfUses()

  }, []);

 
  return (
    <>
      <div>
        <div className='px-md-5'>

          <div className="d-flex">
            <div className="col-lg-12">
              <h2 className="text-center mt-5 my-5 login_form_title2">MOV Terms of Service </h2>
              <div className="search-bar">
                {parse(addData)}
              </div>
        </div>
    </div>
    </div>
    </div>
                      
    </>
  );
};
