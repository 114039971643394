import React from 'react'

function Simplify() {
  return (
    <section className="simplify-txt">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h2>Simplify your Asthma and live the life you want.</h2>
            </div>
        </div>
    </div>
</section>
  )
}

export default Simplify
