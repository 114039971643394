
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import { TermsAndConditionData } from './pages/TermsOfUse';
import { PrivacyPolicyData } from './pages/PrivacyPolicy';


function App() {
  return (
    <>

    <Routes>
      <Route path='/' element={<LandingPage/>}/>
      <Route path='/terms-and-conditions' element={<TermsAndConditionData/>}/>
      <Route path='/privacy-policy' element={<PrivacyPolicyData/>}/>
    </Routes>
    </>
  );
}

export default App;
