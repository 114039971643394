import React from 'react'

function Banner() {

    function handleScroll() {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0, 
          behavior: 'smooth',
        });
      }
  return (
    <section className="banner-section">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="mov-logo">
                    <a href="/"><img src={require('../assets/images/logo.png')} className="img-fluid" alt="logo"
                    /></a>
                </div>
            </div>
            <div className="col-md-6">
                <p className="mov-sign-in">
                    <a href="https://my.movhealth.com" target="_blank"><button>Sign In</button></a>
                </p>
            </div>
            <div className="col-md-5">
                <div className="banner-text">
                    <h2>A better way to self-manage your Asthma.</h2>
                    <p>Asthma doesn't need to be burdensome. Take charge of your triggers and keep track of your
                        health - all at the touch of a button.</p>
                </div>
            </div>
            <div className="col-md-7"></div>
        </div>
    </div>

    <center>
        <div className="arrow-down" onClick={handleScroll}>
            <img src={require('../assets/images/arrow-down.png')} className="img-fluid" alt="down Arrow"/>
        </div>
    </center>
</section>
  )
}

export default Banner
