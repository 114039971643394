import React from 'react'

function PointSection() {
  return (
    <section className="points-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h3>Everything you need at your fingertips.</h3>
                <p><img src={require('../assets/images/check-circle.png')} alt = 'Check Circle'/> Real Time, Easy-to-Use Digital Technology</p>
                <p><img src={require('../assets/images/check-circle.png')} alt = 'Check Circle'/> Personalized metrics for any severity level</p>
                <p><img src={require('../assets/images/check-circle.png')} alt = 'Check Circle'/> No more reliance on written journals</p>
                <p><img src={require('../assets/images/check-circle.png')} alt = 'Check Circle'/> Unlimited access to trusted educational tools</p>
                <p><img src={require('../assets/images/check-circle.png')} alt = 'Check Circle'/> On-Demand Access to Certified Asthma Educators</p>
            </div>
        </div>
    </div>
</section>
  )
}

export default PointSection
