import axios from 'axios'
import React, { useState } from 'react'

function ComingSoon() {

    const [formData, setFormData] = useState({
        name: '',
        email: ''
    })
    const [message, setMessage] = useState('')

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios({
                method: 'post',
                url: 'https://api-backend.movhealth.com/api/v1/user/sign_me_up_mov',
                data: formData,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-api-key": "mv52ea2d-4567-4956-9d19-35a7e75a2c17",
                    "platform": "web"
                }
            })
            console.log(response)
            setMessage(response.data.message)
            setFormData({name:'',email:''})
            
        } catch (error) {
            console.log(error.response)
            if (error.response.data?.errors.length > 0) {
                setMessage(error.response.data?.errors[0])

            } else {

                setMessage(error.response.data?.message)
            }

        }
        setTimeout(()=>{setMessage('')},4000)
    }
    return (
        <section className="coming-soon-section" id='comingSoonSection'>
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="coming-soon-txt">
                            <h4>Coming Soon!</h4>
                            <p>Although we are not currently accepting orders, you can join our Waitlist to reserve your
                                spot today!*</p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="coming-soon-form">
                            <form >
                                <p>We'll notify you when we get ready to launch!</p>
                                <input type="text" name="name" placeholder="*Name" required
                                    value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                <input type="email" name="email" placeholder="*E-mail" required value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                <button type="submit" onClick={onSubmit}>Sign Me Up!</button>
                                <p className='mt-1'>{message}</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <p className="warning">*You must have a current diagnosis of Asthma to be eligible for the MOV Program.</p>
        </section>
    )
}

export default ComingSoon
