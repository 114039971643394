import React from 'react'

function OneKit() {
  return (
    <section className="one-kit" id="one-kit">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="one-kit-text">
                    <h4>One Kit. Endless Benefits.</h4>
                    <p>Through a combination of technology, health coaching, and digital tools, MOV provides a
                        personalized approach to Asthma self-management. Our members are empowered to do more with
                        less so they can go about living the life they want, when they want.</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="one-kit-img">
                    <img src={require('../assets/images/phone-img.png')} className="img-fluid" alt='phone'/>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default OneKit
